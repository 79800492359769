import React from 'react';
import './footer.css';
import CupOfCoffee from '../../images/buy-me-a-coffe-white.png';
import { ReactComponent as InstagramIcon } from './instagram.svg'

const Footer = () => {

    const scrollToTop = () => {
        console.log("==> back to top")
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    };

    return (
        <>
            <footer className="w3-container w3-padding-16 w3-light-grey w3-center">
                {/*<GumroadEmailSubscription/>*/}
                <div>
                <span>I can't recommend it, but if you insist, you can follow me on <a href="https://www.instagram.com/madebyjadu">Instagram</a>
                </span>
                </div>
            </footer>

        </>
    );
};

export default Footer;
