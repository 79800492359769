import React, {useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import drawings from "../data/drawings";
import './Drawing.css'
import DrawingDescription from "./text/DrawingDescription";
import DrawingPhotoToggle from "./toggle/DrawingPhotoToggle";
import BackButton from "./BackButton";
import {ScreenContext} from "../pages/Layout";
import LazyLoad from "react-lazy-load";


const Drawing = (props) => {

    const drawing = props.src;
    const [imgHeight, setImageHeight] = useState(0);
    const {screen} = useContext(ScreenContext);
    const [showDrawing, setShowDrawing] = useState(true);
    const [showCourse, setShowCourse] = useState(false);

    const onImageClick = () => {
        if (showDrawing) {
            onToggleOn();
        } else {
            onToggleOff();
        }
    }

    const onToggleOn = () => {
        setShowDrawing(false);
        window.plausible('toggle-on');
    }

    const onToggleOff = () => {
        setShowDrawing(true);
        window.plausible('toggle-off');
    }

    function handleToggleCountTriggered() {
        setShowCourse(true);
    }

    let drawingImageClass = "";
    let photoImageClass = "";
    if (showDrawing) {
        drawingImageClass = "drawing-image";
        photoImageClass = "photo-image transparant";
    } else {
        drawingImageClass = "drawing-image transparant";
        photoImageClass = "photo-image";
    }

    const backLink = "/#" + drawing.name;
    const showLink = drawing && drawing.link;
    const showToggleButtons = false;

    // Always showing the 600px version
    const drawingSrc = screen.isMobile ? drawing.drawing600 : drawing.drawing600;
    const imageSrc = screen.isMobile ? drawing.photo600 : drawing.photo600;

    const onImgLoad = ({ target: img }) => {
        const { offsetHeight, offsetWidth } = img;
        setImageHeight(offsetHeight)
    };

    const onContentVisible = () => {
        window.plausible(drawing.promoText)
    }

    return (
        <>
                <div className="w3-container w3-padding">
                    <div className="w3-cell w3-right-align w3-half">
                            {screen.isMobile && (<h2 className="drawing-promo-center">{drawing.promoText}</h2>)}
                            <div className="image-container" onClick={onImageClick}>
                                <LazyLoad offset={300} onContentVisible={onContentVisible}>
                                    <img onLoad={onImgLoad} src={drawingSrc} className={drawingImageClass} alt={drawing.promoText}/>
                                </LazyLoad>
                                <LazyLoad offset={300}>
                                    <img onLoad={onImgLoad} src={imageSrc} className={photoImageClass} alt={drawing.promoText}/>
                                </LazyLoad>
                            </div>
                    </div>
                    <div className="w3-cell w3-left-align w3-half">
                        <div className="w3-padding">
                            {!screen.isMobile && (<h2 className="drawing-promo-left">{drawing.promoText}</h2>)}
                            <DrawingDescription description={drawing.description}/>
                            {showLink && (
                                <a href={drawing.link.url} target="_blank" rel="noreferrer">{drawing.link.text}</a>
                            )}
                        </div>
                    </div>
                                <div className=" w3-container w3-padding-16">
                                    <DrawingPhotoToggle
                                            toggleOn={showDrawing}
                                            onToggleOn={onToggleOn}
                                            onToggleOff={onToggleOff}
                                            onToggleCountTriggered={handleToggleCountTriggered}
                                            showButtons={showToggleButtons} />
                                </div>
                </div>
        </>
    );
};

export default Drawing;
