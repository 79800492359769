import React, {useContext, useState} from 'react';
import {useParams} from 'react-router-dom';
import drawings from "../data/drawings";
import './DrawingDetail.css'
import DrawingDescription from "./text/DrawingDescription";
import DrawingPhotoToggle from "./toggle/DrawingPhotoToggle";
import BackButton from "./BackButton";
import {ScreenContext} from "../pages/Layout";
import Drawing from './Drawing.js';

const DrawingDetail = (props) => {

    const {screen} = useContext(ScreenContext);
    const [showDrawing, setShowDrawing] = useState(true);
    const [showCourse, setShowCourse] = useState(false);

    let params = useParams();
    const drawing = drawings.find(obj => {
        return obj.name === params.drawingName;
    })

    return (
        <>
        <div className="w3-container w3-padding-32">
        <Drawing key={drawing.name} src={drawing}/>
        </div>
        </>
    );
};

export default DrawingDetail;
