import React from "react";
import BackButton from "../components/BackButton";
import SpokenText from "../components/SpokenText";

const About = () => {
    return (
        <div className="w3-container ">
            <h3>About</h3>
            <p>This site is just a convenient place to store all my drawings.</p>
            <p>So, for example, if I would be on a train, and a stranger would suddenly ask me:</p>
            <SpokenText text="Show me your drawings! NOW!"/>
            <p>Then I can calmly reply:</p>
            <SpokenText text="Ofcourse, here they are."/>
            <p>and show this website.</p>
            <p>And then all the people in the train would relax, and everything in the universe would be fine.</p>
        </div>
    );
};

export default About;

