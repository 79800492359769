import React, {useEffect, useState} from 'react';
import './DrawingPhotoToggle.css'

const DrawingPhotoToggle = (props) => {

    const [toggleOn, setToggleOn] = useState(true);
    const [toggleCount, setToggleCount] = useState(0);
    const [status, setStatus] = useState(false);
    const [overlayClass, setOverlayClass] = useState("");

    function doToggle() {
        setStatus(!status);
        if (toggleOn) {
            setOverlayClass("overlay-right");
            setToggleOn(false);
            props.onToggleOn();
        } else {
            setOverlayClass("overlay-left");
            setToggleOn(true);
            props.onToggleOff();
        }
        setToggleCount(toggleCount+1);
        console.log("togglecount: " + toggleCount)
        if (toggleCount > 0) {
            props.onToggleCountTriggered();
        }
    }

    useEffect(() => {
        if (toggleOn && !props.toggleOn){
            doToggle();
        } else if (!toggleOn && props.toggleOn){
            doToggle();
        }
    }, [props.toggleOn]);

    return (
        <>
            <div className="toggle-container">
                {props.showButtons && (
                    <>
                        <div className="drawing-button button-style" onClick={doToggle} >Drawing</div>
                        <div className="photo-button button-style" onClick={doToggle} >Photo</div>
                        <div id="overlay" className={overlayClass} onClick={doToggle}></div>
                    </>
                )}
            </div>
        </>
    );
};

export default DrawingPhotoToggle;
