import React from 'react';
import Drawing from '../components/Drawing.js';
import drawings from "../data/drawings";
import './home.css';


const Home = () => {

    return (
        <>
            <div className="w3-row subTitle">
                <div className="w3-center w3-opacity"><p>All the stuff made by Jadu</p></div>
            </div>
            <div id="drawingList" className="w3-container">
                    {drawings.map((drawing, index) => (
                        <Drawing key={drawing.name} index={index} src={drawing}/>
                    ))}
            </div>
        </>
    );
};

export default Home;

