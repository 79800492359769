import React, {useState} from 'react';
import {Link} from "react-router-dom";
import './header.css';
import Navbar from "../Navbar";
import { ReactComponent as BarsIcon } from './bars.svg'
import { ReactComponent as HouseIcon } from './house.svg'

const Header = (props) => {

    const [isShowSideBar, setShowSideBar] = useState(false);

    const showSideBar = () => {
        setShowSideBar(true);
    };

    return (
        <header className="w3-margin-bottom">
            {!isShowSideBar && (
                <>
                    <div className="header-container">
                        <div className="header-left" >
                           <span className="w3-button w3-xlarge w3-white w3-left">
                              <Link to={"/"} className="no-underline"><HouseIcon className="w3-opacity" width="24px" height="24px"/></Link>
                           </span>
                        </div>
                        <div className="header-center w3-center w3-opacity">
                            <Link to={"/"} className="no-underline"><h3><b>Made By Jadu</b></h3></Link>
                        </div>
                        <div className="header-right" >
                            <span className="w3-button w3-xlarge w3-white w3-right" onClick={showSideBar}>
                                <BarsIcon className="w3-opacity" width="24px" height="24px"/>
                            </span>
                        </div>
                    </div>
                </>)
            }
            {isShowSideBar && (
                <Navbar/>
            )}
        </header>
    );
};

export default Header;
