import React from 'react';
import BackButton from "../components/BackButton";

const Contact = () => {
    return (
        <div className="w3-container w3-center">
            <h3>Contact</h3>
            <p>If you have any questions, you can reach me at <a
                href="mailto:madebyjadu@gmail.com">madebyjadu@gmail.com</a></p>
        </div>
    );
};

export default Contact;

