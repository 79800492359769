import React from "react";
import './navbar.css'
import { ReactComponent as XMarkIcon } from './xmark.svg'

const Navbar = (props) => {

    return (
        <>
            <nav className="w3-sidebar w3-black w3-animate-top w3-xxlarge" style={{width: '100%'}} id="mySidebar">
                <button onClick={props.hideSideBar}
                        className="w3-button w3-black w3-xxlarge w3-padding w3-display-topright"
                        style={{padding: '6px 24px'}}>
                    <XMarkIcon width="24px" height="24px"/>
                </button>
                <div className="w3-bar-block w3-center">
                    <a href="/" className="w3-bar-item w3-button w3-text-grey w3-hover-black">Home</a>
                    <a href="/archive" className="w3-bar-item w3-button w3-text-grey w3-hover-black">Archive</a>
                    <a href="/about" className="w3-bar-item w3-button w3-text-grey w3-hover-black">About</a>
                    <a href="/contact" className="w3-bar-item w3-button w3-text-grey w3-hover-black">Contact</a>
                </div>
            </nav>
        </>
    );

};

export default Navbar;

